import TakeAwayForm from '@/components/TakeAway/TakeAwayForm';
import ModalBox from '@/components/ModalBox';
import { useToast } from 'vue-toastification';
import { defineComponent } from 'vue';
import useTakeAway from '@/compositions/takeaway';
import useReservations from '@/compositions/reservations';
import { cloneDeep } from 'lodash';
export default defineComponent({
    name: 'EditTakeAwayButton',
    components: {
        ModalBox,
        TakeAwayForm
    },
    props: {
        title: {
            type: String,
            required: false,
            default: 'Aanpassen'
        },
        id: {
            type: String,
            required: true
        }
    },
    setup() {
        return { ...useReservations(), ...useTakeAway(), toast: useToast() };
    },
    data: () => ({
        showModal: false,
        isValid: false,
        takeaway: undefined,
        processing: false
    }),
    computed: {
        storeTakeAway() {
            return this.takeaways[this.id];
        },
        storeReservation() {
            if (this.storeTakeAway === undefined)
                return undefined;
            return this.reservations[this.storeTakeAway.reservationId];
        }
    },
    watch: {
        storeTakeAway: {
            handler() {
                this.takeaway = cloneDeep(this.storeTakeAway);
            },
            immediate: true
        }
    },
    methods: {
        async updateTakeAway() {
            if (this.takeaway === undefined)
                return;
            if (!this.isValid)
                return;
            this.processing = true;
            try {
                await this.$store.direct.dispatch.takeaway.update({ id: this.id, update: this.takeaway });
                this.toast.success('Uw takeaway werd successvol aangepast');
            }
            catch (error) {
                this.toast.error(`Er is een fout opgetreden bij het aanpassen van de takeaway ${error}`);
            }
            this.showModal = false;
            this.processing = false;
        }
    }
});
