import { defineComponent } from 'vue';
import EditTakeAwayButton from '@/components/TakeAway/EditTakeAwayButton';
import useProducts from '@/compositions/products';
import useTakeAway from '@/compositions/takeaway';
import { convertTimestamp } from '@/classes/timestamps';
import useAuth from '@/compositions/auth';
export default defineComponent({
    name: 'CustomerTakeAway',
    components: {
        EditTakeAwayButton
    },
    setup() {
        return {
            ...useProducts(),
            ...useTakeAway(false),
            ...useAuth()
        };
    },
    computed: {
        isSuperUser() {
            return this.$store.direct.getters.auth.isSuperUser;
        }
    },
    mounted() {
    },
    methods: {
        async deleteTakeAway(id) {
            try {
                await this.$store.direct.dispatch.takeaway.delete(id);
                console.log('Takeaway deleted');
            }
            catch (error) {
                console.error('Could not delete takeaway', error);
            }
        },
        printPickUpDay(d) {
            return convertTimestamp(d.getTime());
        },
        getTotalPrice(ta) {
            return ta.totalPrice();
        },
        customerName() {
            var _a, _b;
            return ((_a = this.userinfo) === null || _a === void 0 ? void 0 : _a.firstname) + ' ' + ((_b = this.userinfo) === null || _b === void 0 ? void 0 : _b.lastname);
        }
    }
});
