import useReservations from '@/compositions/reservations';
import { defineComponent } from 'vue';
import { convertTimestamp } from '@/classes/timestamps';
import useAuth from '@/compositions/auth';
export default defineComponent({
    name: 'CustomerReservations',
    setup() {
        return { ...useReservations(false), ...useAuth() };
    },
    data: () => ({}),
    computed: {
        filter() {
            return this.$store.direct.state.reservations.filter;
        }
    },
    mounted() {
    },
    methods: {
        printDate(d) {
            return convertTimestamp(d.getTime());
        },
        customerName() {
            var _a, _b;
            return ((_a = this.userinfo) === null || _a === void 0 ? void 0 : _a.firstname) + ' ' + ((_b = this.userinfo) === null || _b === void 0 ? void 0 : _b.lastname);
        },
        inReview(reservation) {
            return reservation.accepted ? '' : ' - in behandeling';
        }
    }
});
