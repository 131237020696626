import { defineComponent } from 'vue';
import EditProfileButton from '@/components/Navbar/EditProfileButton';
import CustomerReservations from '@/components/Reservation/CustomerReservations';
import CustomerTakeAway from '@/components/TakeAway/CustomerTakeAway';
import Calendar from '@/components/Calendar';
export default defineComponent({
    name: 'Home',
    components: {
        EditProfileButton,
        CustomerReservations,
        CustomerTakeAway,
        Calendar
    },
    data: () => ({
        editProfile: null,
        showNewReservationModal: false,
        showEditProfileModal: false
    }),
    computed: {
        isSuperUser() {
            return this.$store.direct.getters.auth.isSuperUser;
        },
        isLoggedIn() {
            return this.$store.direct.getters.auth.isLoggedIn;
        },
        uid() {
            var _a, _b;
            return (_b = (_a = this.$store.direct.state.auth.user) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : undefined;
        },
        firstname() {
            var _a;
            return (_a = this.$store.direct.state.auth.userInfo) === null || _a === void 0 ? void 0 : _a.firstname;
        },
        imageMakeReservation() {
            return require('@/assets/newreservation.svg');
        }
    },
    methods: {
        logout() {
            this.$store.direct.dispatch.auth.logout();
        },
        loginWithGoogle() {
            this.$store.direct.dispatch.auth.loginWithGoogle().then(() => {
                console.log('login successfull');
            }).catch(error => {
                console.error('could not log in', error);
            });
        },
        loginWithFacebook() {
            this.$store.direct.dispatch.auth.loginWithFacebook().then(() => {
                console.log('login successfull');
            }).catch(error => {
                console.error('could not log in', error);
            });
        }
    }
});
